import hljs from 'highlight.js/lib/core';

import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import xml from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import sql from 'highlight.js/lib/languages/sql';
import typescript from 'highlight.js/lib/languages/typescript';
import shell from 'highlight.js/lib/languages/shell';
import java from 'highlight.js/lib/languages/java';
import csp from 'highlight.js/lib/languages/csp';
import cpp from 'highlight.js/lib/languages/cpp';
import c from 'highlight.js/lib/languages/c';
import php from 'highlight.js/lib/languages/php';
import powershell from 'highlight.js/lib/languages/powershell';
import go from 'highlight.js/lib/languages/go';
import rust from 'highlight.js/lib/languages/rust';
import kotlin from 'highlight.js/lib/languages/kotlin';
import ruby from 'highlight.js/lib/languages/ruby';
import lua from 'highlight.js/lib/languages/lua';
import dart from 'highlight.js/lib/languages/dart';
import armasm from 'highlight.js/lib/languages/armasm';
import swift from 'highlight.js/lib/languages/swift';
import r from 'highlight.js/lib/languages/r';


export const registerLanguages = () => {
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('python', python);
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('xml', xml);
    hljs.registerLanguage('css', css);
    hljs.registerLanguage('python', python);
    hljs.registerLanguage('sql', sql);
    hljs.registerLanguage('typescript', typescript);
    hljs.registerLanguage('shell', shell);
    hljs.registerLanguage('java', java);
    hljs.registerLanguage('csp', csp);
    hljs.registerLanguage('cpp', cpp);
    hljs.registerLanguage('c', c);
    hljs.registerLanguage('php', php);
    hljs.registerLanguage('powershell', powershell);
    hljs.registerLanguage('go', go);
    hljs.registerLanguage('rust', rust);
    hljs.registerLanguage('kotlin', kotlin);
    hljs.registerLanguage('ruby', ruby);
    hljs.registerLanguage('lua', lua);
    hljs.registerLanguage('dart', dart);
    hljs.registerLanguage('armasm', armasm);
    hljs.registerLanguage('swift', swift);
    hljs.registerLanguage('r', r);
};