import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Fade,
  Flex,
  Link,
  SimpleGrid,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import LiquidLoadingSpinner from '../utils/loading';
import { BsBorderWidth } from "react-icons/bs";
import { FiGlobe } from "react-icons/fi";

const getBaseUrl = (url) => {
  try {
    const { hostname } = new URL(url);
    return hostname.replace(/^www\./, '');
  } catch (error) {
    console.error('Invalid URL:', url, error);
    return null;
  }
};

const getMainDomain = (url) => {
  const baseUrl = getBaseUrl(url);
  if (!baseUrl) return null;
  const parts = baseUrl.split('.');
  return parts.length >= 2 ? `${parts[parts.length - 2]}.${parts[parts.length - 1]}` : baseUrl;
};

const fetchServiceLogo = async (url) => {
  const baseUrl = getBaseUrl(url);
  if (!baseUrl) return '/default-logo.svg';

  const faviconUrl = `https://www.google.com/s2/favicons?sz=128&domain=https://${baseUrl}`;
  const img = new Image();
  return new Promise((resolve) => {
    img.onload = () => {
      if (img.width === 16 && img.height === 16) {
        const mainDomain = getMainDomain(url);
        if (mainDomain) {
          resolve(`https://www.google.com/s2/favicons?sz=128&domain=https://${mainDomain}`);
        } else {
          resolve(faviconUrl);
        }
      } else {
        resolve(faviconUrl);
      }
    };
    img.onerror = () => {
      resolve('/default-logo.svg');
    };
    img.src = faviconUrl;
  });
};

const SourceCard = ({ content, reference, created_at, showDate, service, favicon_icon, index }) => {
  const serviceName = getBaseUrl(reference) || 'Unknown Service';
  const dateObj = new Date(created_at);
  const isValidDate = !isNaN(dateObj.getTime());
  const formattedDate = isValidDate ? dateObj.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }) : '';

  const hideDateForWebSource = service && service.length > 0;

  return (
    <Link href={reference} isExternal key={reference}>
      <Box
        maxWidth={{ base: "100%", md: "300px" }}
        py="2"
        px="2"
        borderRadius="md"
        borderWidth="1px"
        borderColor="gray.800"
        bg="gray.800"
        display="flex"
        flexDirection="column"
        overflow="hidden"
        height="100%"
        _hover={{
          bg: "gray.700"
        }}
      >
        <Flex flexDirection='row' alignItems="center">
          {index !== undefined && (
            <Text fontSize="s" fontWeight="700" mr="5px">
              {index + 2}.
            </Text>
          )}
          <Box bg="gray.900" borderRadius="full" p="1" overflow="hidden" lineHeight="0" w="22px" h="22px" display="flex" justifyContent="center" alignItems="center" mr="5px">
            <img src={favicon_icon} alt="Service Logo" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '50%' }} />
          </Box>
          <Text noOfLines={1} fontSize="xs" fontWeight="700" opacity="0.7">
            {serviceName}
          </Text>
        </Flex>
        <Text px="5px" noOfLines={2} fontSize="xs" mt="7px" fontWeight="700" opacity="0.8">
          {content}
        </Text>
        {showDate && hideDateForWebSource && <Text fontSize="xs" textAlign="right" mt="10px" mr="3px" opacity="0.8">{formattedDate}</Text>}
      </Box>
    </Link>
  );
};

const SourcesList = ({ sources, showSkeleton }) => {
  const { isOpen: isServiceDrawerOpen, onOpen: onServiceDrawerOpen, onClose: onServiceDrawerClose } = useDisclosure();
  const { isOpen: isNoServiceDrawerOpen, onOpen: onNoServiceDrawerOpen, onClose: onNoServiceDrawerClose } = useDisclosure();
  const [logos, setLogos] = useState({});

  useEffect(() => {
    const fetchLogos = async () => {
      const newLogos = {};
      for (const source of sources || []) {
        const logoUrl = await fetchServiceLogo(source.reference);
        newLogos[source.reference] = logoUrl;
      }
      setLogos(newLogos);
    };
    fetchLogos();
  }, [sources]);

  const renderSkeletons = () => {
    return Array.from({ length: 4 }).map((_, index) => (
      <Fade key={index} in={showSkeleton} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
        <Box p="2" borderRadius="md" borderWidth="1px" borderColor="gray.800" maxWidth="300px" w="full">
          <Flex direction="row" alignItems="center">
            <Skeleton startColor='gray.600' endColor='gray.700' height="18px" width="18px" mb="4" borderRadius="full" mr="5px" />
            <Skeleton startColor='gray.600' endColor='gray.700' height="10px" width="50%" mb="4" />
          </Flex>
          <Skeleton startColor='gray.600' endColor='gray.700' height="13px" mb="2" />
          <Skeleton startColor='gray.600' endColor='gray.700' height="13px" />
        </Box>
      </Fade>
    ));
  };

  if (showSkeleton === true) {
    return (
      <>
        <Flex direction="row" alignItems="center" p="1.5em 1.5rem 0 1.5rem" >
          <LiquidLoadingSpinner />
          <Text ml="20px" fontWeight="500" fontSize="xl">Searching data</Text>
        </Flex>
        <SimpleGrid columns={[1, 2, 3, 4]} spacing="10px" justifyContent="center" mb="30px" w="100%" p="1.5rem">
          {renderSkeletons()}
        </SimpleGrid>
      </>
    );
  }

  if (!sources || sources.length === 0) {
    return null;
  }

  const sourcesWithService = sources.filter(source => source.service);
  const sourcesWithoutService = sources.filter(source => !source.service);

  const renderSourceList = (sources, onOpen) => {
    const remainingCount = sources.length > 1 ? sources.length - 1 : 0;
    const remainingSourcesForList = sources.slice(1);

    const IconStack = ({ sources }) => {
      return (
        <Flex direction="row" alignItems="center" gap="5px">
          {sources.map((source, index) => (
            <Box key={index} bg="gray.900" borderRadius="full" p="1" overflow="hidden" lineHeight="0" w="22px" h="22px" display="flex" justifyContent="center" alignItems="center">
              <img src={logos[source.reference] || '/default-logo.svg'} alt="Service Logo" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '50%' }} />
            </Box>
          ))}
        </Flex>
      );
    };

    return (
      <>
        <SimpleGrid columns={[1, 2]} spacing="10px" justifyContent="center">
          {sources.slice(0, 1).map((source, index) => (
            <SourceCard key={index} {...source} favicon_icon={logos[source.reference] || '/default-logo.svg'} showDate={false} service={source.service} />
          ))}
          {remainingCount > 0 && (
            <Box
              onClick={onOpen}
              cursor="pointer"
              p="4"
              borderRadius="md"
              borderWidth="1px"
              borderColor="gray.800"
              bg="gray.800"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
              _hover={{
                bg: "gray.700"
              }}
            >
              <IconStack sources={remainingSourcesForList.slice(0, 5)} />
              <Text mt="10px" fontSize="0.75em" color="gray.300" fontWeight="700" opacity="0.7">{`See ${remainingCount} More`}</Text>
            </Box>
          )}
        </SimpleGrid>
      </>
    );
  };

  return (
    <>
      <Fade in={!showSkeleton && sources.length > 0} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
        <SimpleGrid spacingX="10px" spacingY="30px" columns={[1, null, 2]} mb="30px">
          <Box>
            <Flex direction="row" mb="10px" alignItems="center">
              <BsBorderWidth size="24px" />
              <Text ml="20px" fontWeight="500" fontSize="xl">Your Data</Text>
            </Flex>
            {renderSourceList(sourcesWithService, onServiceDrawerOpen)}
          </Box>
          <Box>
            {sourcesWithoutService.length > 0 && (
              <Flex direction="row" mb="10px" alignItems="center">
                <FiGlobe size="24px" />
                <Text ml="20px" fontWeight="500" fontSize="xl">Online Sources</Text>
              </Flex>
            )}
            {renderSourceList(sourcesWithoutService, onNoServiceDrawerOpen)}
          </Box>
        </SimpleGrid>
      </Fade>

      <Drawer placement="right" onClose={onServiceDrawerClose} isOpen={isServiceDrawerOpen} transition={{ enter: { duration: 0.3 } }} size="sm">
        <DrawerOverlay />
        <DrawerContent bg="gray.900">
          <DrawerCloseButton />
          <DrawerBody p={0}>
            <SimpleGrid p="4" spacing="4">
              {sourcesWithService.slice(1).map((source, index) => (
                <SourceCard key={index} {...source} favicon_icon={logos[source.reference] || '/default-logo.svg'} showDate={true} service={source.service} index={index} />
              ))}
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer placement="right" onClose={onNoServiceDrawerClose} isOpen={isNoServiceDrawerOpen} transition={{ enter: { duration: 0.3 } }} size="sm">
        <DrawerOverlay />
        <DrawerContent bg="gray.900">
          <DrawerCloseButton />
          <DrawerBody p={0}>
            <SimpleGrid p="4" spacing="4">
              {sourcesWithoutService.slice(1).map((source, index) => (
                <SourceCard key={index} {...source} favicon_icon={logos[source.reference] || '/default-logo.svg'} showDate={true} service={source.service} index={index} />
              ))}
            </SimpleGrid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SourcesList;
