import React, { memo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import rehypeExternalLinks from 'rehype-external-links';
import 'highlight.js/styles/github-dark.css';
import { IconButton, Tooltip, Box } from "@chakra-ui/react";
import { FiClipboard, FiCheck } from "react-icons/fi";
import { PiThumbsDownBold, PiThumbsDownFill } from "react-icons/pi";

const MarkdownWithHighlight = ({ text, isAssistantAnswer, onThumbsDownClick, thumbsDown }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator
      .clipboard.writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <Box position="relative">
      {typeof text === 'string' ?
        <ReactMarkdown
          rehypePlugins={[
            rehypeRaw,
            rehypeHighlight,
            [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
          ]}
        >
          {text}
        </ReactMarkdown> :
        text
      }

      {isAssistantAnswer && (
        <Box h="40px" display="flex" alignItems="center">
          <Tooltip
            label="Bad response"
            hasArrow
            bg='black'
            placement='top'
            color='gray.300'
            p={4}
            borderRadius={10}
            openDelay={400}>
            <IconButton
              icon={thumbsDown ? <PiThumbsDownFill /> : <PiThumbsDownBold />}
              onClick={onThumbsDownClick}
              aria-label="Thumbs down"
              color={"gray.400 !important"}
              variant="ghost"
              _hover={{
                color: "gray.300",
                bg: 'gray.800',
              }}
              position="absolute"
              bottom="0"
              right="40px"
            />
          </Tooltip>
          <Tooltip
            label="Copy full answer"
            hasArrow
            bg='black'
            placement='top'
            color='gray.300'
            p={4}
            borderRadius={10}
            openDelay={400}>
            <IconButton
              icon={isCopied ? <FiCheck /> : <FiClipboard />}
              onClick={copyToClipboard}
              aria-label="Copy to clipboard"
              color="gray.400"
              variant="ghost"
              _hover={{
                color: "gray.300",
                bg: 'gray.800',
              }}
              position="absolute"
              bottom="0"
              right="0"
            />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default memo(MarkdownWithHighlight);
