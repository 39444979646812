import {
    Box,
    keyframes, 
    usePrefersReducedMotion,
    VStack,
    theme
  } from '@chakra-ui/react';

const liquidAnimation = keyframes`
  0%, 100% {
    transform: translate(0, 0) rotate(0) scale(1);
    opacity: 1;
    box-shadow: 0 0 4px var(--shadow-color, rgba(0, 0, 0, 0.2));
  }
  10% {
    transform: translate(-5%, -5%) rotate(-3deg) scale(1.05);
    opacity: 0.7;
    box-shadow: 0 0 3px var(--shadow-color, rgba(0, 0, 0, 0.4));
  }
  20% {
    transform: translate(5%, 5%) rotate(3deg) scale(0.95);
    opacity: 0.85;
    box-shadow: 0 0 2px var(--shadow-color, rgba(0, 0, 0, 0.2));
  }
  30% {
    transform: translate(-5%, 5%) rotate(-3deg) scale(1.05);
    opacity: 0.5;
    box-shadow: 0 0 8px var(--shadow-color, rgba(0, 0, 0, 0.5));
  }
  40% {
    transform: translate(5%, -5%) rotate(3deg) scale(0.95);
    opacity: 0.8;
    box-shadow: 0 0 12px var(--shadow-color, rgba(0, 0, 0, 0.5));
  }
  50% {
    transform: translate(0, 0) rotate(0) scale(1);
    opacity: 1;
    box-shadow: 0 0 9px var(--shadow-color, rgba(0, 0, 0, 0.3));
  }
  60% {
    transform: translate(-5%, 5%) rotate(-3deg) scale(1.05);
    opacity: 0.9;
    box-shadow: 0 0 3px var(--shadow-color, rgba(0, 0, 0, 0.1));
  }
  70% {
    transform: translate(5%, -5%) rotate(3deg) scale(0.95);
    opacity: 0.65;
    box-shadow: 0 0 8px var(--shadow-color, rgba(0, 0, 0, 0.6));
  }
  80% {
    transform: translate(-5%, -5%) rotate(-3deg) scale(1.05);
    opacity: 0.9;
    box-shadow: 0 0 15px var(--shadow-color, rgba(0, 0, 0, 0.5));
  }
  90% {
    transform: translate(5%, 5%) rotate(3deg) scale(0.95);
    opacity: 0.85;
    box-shadow: 0 0 8px var(--shadow-color, rgba(0, 0, 0, 0.2));
  }
`;

const grayAnimation = keyframes`
  0%, 100% {
    transform: rotate(0) scale(1);
    box-shadow: 0 0 8px var(--shadow-color, rgba(0, 0, 0, 0.2));
  }
  25% {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 0 12px var(--shadow-color, rgba(0, 0, 0, 0.2));
  }
  50% {
    transform: rotate(5deg) scale(0.90);
    box-shadow: 0 0 9px var(--shadow-color, rgba(0, 0, 0, 0.2));
  }
  75% {
    transform: rotate(-5deg) scale(1.1);
    box-shadow: 0 0 4px var(--shadow-color, rgba(0, 0, 0, 0.2));
  }
`;

const getColorRGB = (theme, color) => {
  const [colorName, colorShade] = color.split('.');
  const rgb = theme.colors[colorName][colorShade];
  const r = parseInt(rgb.slice(1, 3), 16),
        g = parseInt(rgb.slice(3, 5), 16),
        b = parseInt(rgb.slice(5, 7), 16);

  return `rgb(${r}, ${g}, ${b})`;
};

const LiquidBox = ({ color, delay, size, isGray }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const rgbColor = getColorRGB(theme, color);

  const animation = prefersReducedMotion
    ? undefined
    : isGray
      ? `${grayAnimation} 7s infinite`
      : `${liquidAnimation} 7s infinite linear ${delay}s`;

  return (
    <Box
      width={size.width}
      height={size.height}
      bgColor={color}
      position="absolute"
      borderRadius="50%"
      animation={animation}
      style={{
        '--shadow-color': `${rgbColor}`,
      }}
    />
  );
};

const LiquidLoadingSpinner = () => {
  const colors = ['cyan.600', 'green.400', 'gray.100', 'blue.500', 'gray.900'];
  const delays = colors.map((_, index) => index * 0.8);

  return (
    <VStack position="relative" width="30px" height="30px">
      {colors.map((color, index) => {
        const size = color === 'gray.900' ? { width: '85%', height: '85%' } : { width: '100%', height: '100%' };
        const isGray = color === 'gray.900'; 
        return (
          <LiquidBox
            key={index}
            color={color}
            delay={delays[index]}
            size={size}
            isGray={isGray}
            style={{
              boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
            }}
          />
        );
      })}
    </VStack>
  );
};

export default LiquidLoadingSpinner;