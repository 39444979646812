import { supabaseClient } from "./client";
import React, { useCallback } from 'react';

import styles from '../styles/MarkdownEditor.module.css';
import { Tooltip, Button, Flex, IconButton } from "@chakra-ui/react";

import { BiCodeBlock, BiCodeAlt } from "react-icons/bi";
import { BsListOl, BsListUl } from "react-icons/bs";

export const saveMessageToSupabase = async (userId, message, chatId, senderType) => {
  const { data, error } = await supabaseClient
      .from("chat_messages")
      .insert([
          {
              supabase_user_id: userId,
              content: message,
              chat_id: chatId,
              role: senderType
          }
      ])
      .select()
      .single();

  if (error) {
      console.error("Error saving message:", error);
      return null;
  }

  if (!data) {
      console.error("No data returned from Supabase insert.");
      return null;
  }
  return data.id;
};


export const saveGuestMessageToSupabase = async (message, chatId, senderType) => {
    const { data, error } = await supabaseClient
        .from("chat_messages_guests")
        .insert([
            {
                content: message,
                chat_id: chatId,
                role: senderType
            }
        ])
        .select()
        .single();
  
    if (error) {
        console.error("Error saving message:", error);
        return null;
    }
  
    if (!data) {
        console.error("No data returned from Supabase insert.");
        return null;
    }
    return data.id;
  };

export const updateMessageToSupabase = async (messageId, message) => {
  const { data, error } = await supabaseClient
      .from("chat_messages")
      .update({ content: message })
      .eq("id", messageId)
}

export const updateGuestMessageToSupabase = async (messageId, message) => {
    const { data, error } = await supabaseClient
        .from("chat_messages_guests")
        .update({ content: message })
        .eq("id", messageId)
}

export const createNewChat = async (userId, name) => {
    try {
        const { data, error } = await supabaseClient
            .from('chats')
            .insert([{
                supabase_user_id: userId,
                name: name,
                last_opened_at: new Date().toISOString(),
                last_message_at: new Date().toISOString()
            }])
            .select();

        if (error) throw error;

        return data[0].id;
    } catch (error) {
        console.error("Error creating a new chat:", error.message);
        return null;
    }
}

export const createNewGuestChat = async (name) => {
    try {
        const { data, error } = await supabaseClient
            .from('chats_guests')
            .insert([{
                name: name,
                last_opened_at: new Date().toISOString(),
                last_message_at: new Date().toISOString()
            }])
            .select();

        if (error) throw error;

        return data[0].id;
    } catch (error) {
        console.error("Error creating a new chat:", error.message);
        return null;
    }
}

// STYLING RELATED

export  function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote':
            return styles.RichEditorBlockquote;
        case 'ordered-list-item':
        case 'unordered-list-item':
            return styles.listItem;
        default:
            return null;
    }
}

export const styleMap = {
    CODE: {
        backgroundColor: 'rgba(113, 171, 236, 0.2)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: "0 4px",
        borderRight: "1px solid rgba(113, 171, 236, 0.2)",
        borderLeft: "1px solid rgba(113, 171, 236, 0.2)",
        backgroundClip: "padding-box"
    }
};

const BLOCK_TYPES = [
    { label: 'Ordered List', style: 'ordered-list-item', icon: BsListOl, tooltipLabel: "Ordered List" },
    { label: 'Unordered List', style: 'unordered-list-item', icon: BsListUl, tooltipLabel: "Bullet List" },
    { label: 'Code Block', style: 'code-block', icon: BiCodeBlock, tooltipLabel: "Code Block" },
];

const INLINE_STYLES = [
    { label: 'Inline Code', style: 'CODE', icon: BiCodeAlt, tooltipLabel: "Inline Code" },
];

const StyleButton = React.memo(({ onToggle, active, disabled, style, tooltipLabel, icon: Icon, editorRef, isEditorLoading }) => {
    disabled = isEditorLoading || disabled;

    const handleMouseDown = useCallback((e) => {
        e.preventDefault();

        if (disabled) return;

        if (editorRef && editorRef.current) {
            editorRef.current.focus();
        }

        onToggle(style);
    }, [editorRef, onToggle, style, disabled])

    const buttonStyle = {
        variant: active ? "solid" : "ghost",
        color: active ? "gray.300" : "gray.400",
        opacity: disabled ? "0.2" : "1",
        _hover: disabled ? {} : { bg: 'gray.800', color: 'gray.300' },
        cursor: disabled ? "default" : "pointer",
    };

    return (
        <Tooltip
            label={tooltipLabel}
            hasArrow
            bg='black'
            placement='top'
            color='gray.300'
            p={4}
            borderRadius={10}
            openDelay={400}
        >
            <Button
                {...buttonStyle}
                onMouseDown={handleMouseDown}
                size="md"
                h="40px"
                w="40px"
                p="5px"
            >
                {Icon ? <Icon color={buttonStyle.color} size="1.2em" /> : null}
            </Button>
        </Tooltip>
    );
});

export const BlockStyleControls = ({ editorState, onToggle, editorRef, isEditorLoading }) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockType = contentState.getBlockForKey(selection.getStartKey()).getType();
    const isInlineCodeActive = currentStyle.has('CODE');

    return (
        <div>
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === type.style}
                    disabled={type.style === 'code-block' && isInlineCodeActive}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                    icon={type.icon}
                    tooltipLabel={type.tooltipLabel}
                    editorRef={editorRef}
                    editorState={editorState}
                    isEditorLoading={isEditorLoading}
                />
            )}
        </div>
    );
};

export const InlineStyleControls = ({ editorState, onToggle, editorRef, isEditorLoading }) => {
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
    const isCodeBlockActive = blockType === 'code-block';

    return (
        <div>
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={editorState.getCurrentInlineStyle().has(type.style)}
                    disabled={type.style === 'CODE' && isCodeBlockActive}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                    icon={type.icon}
                    tooltipLabel={type.tooltipLabel}
                    editorState={editorState}
                    editorRef={editorRef}
                    isEditorLoading={isEditorLoading}
                />
            )}
        </div>
    );
};

export function CustomIconButton({ icon, onClick, isActive, ...rest }) {
    const buttonProps = isActive ? {
        colorScheme: 'blue',
        variant: 'solid',
        cursor: 'pointer',
    } : {
        colorScheme: 'gray',
        variant: 'solid',
        cursor: 'default',
        _hover: {
            bg: 'whiteAlpha.200'
        }
    };

    return (
        <IconButton
            icon={icon}
            onClick={isActive ? onClick : () => { }}
            {...buttonProps}
            sx={{
                opacity: isActive ? 1 : 0.4,
            }}
            {...rest}
        />
    );
}

export function customStateToMarkdown(contentState) {
    let markdownString = '';
    let inCodeBlock = false;

    contentState.getBlockMap().forEach((block) => {
        const blockType = block.getType();
        const text = block.getText();

        if (blockType === 'code-block') {
            if (!inCodeBlock) {
                markdownString += '```\n';
                inCodeBlock = true;
            }
            markdownString += `${text}\n`;
        } else {
            if (inCodeBlock) {
                markdownString += '```\n';
                inCodeBlock = false;
            }

            const characterList = block.getCharacterList();
            let processedText = '';
            let isInsideInlineCode = false;

            characterList.forEach((char, index) => {
                if (char.hasStyle('CODE')) {
                    if (!isInsideInlineCode) {
                        processedText += '`';
                        isInsideInlineCode = true;
                    }
                } else {
                    if (isInsideInlineCode) {
                        processedText += '`';
                        isInsideInlineCode = false;
                    }
                }
                processedText += text[index];
            });

            if (isInsideInlineCode) {
                processedText += '`';
            }

            switch (blockType) {
                case 'ordered-list-item':
                    markdownString += `1. ${processedText}\n`;
                    break;
                case 'unordered-list-item':
                    markdownString += `- ${processedText}\n`;
                    break;
                default:
                    markdownString += `${processedText}\n`;
            }
        }
    });

    if (inCodeBlock) {
        markdownString += '```\n';
    }

    return markdownString;
}