import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  useMediaQuery,
  SimpleGrid,
  Input
} from "@chakra-ui/react";
import { useRouter } from 'next/router';
import Head from "next/head";
import { motion } from 'framer-motion';
import styles from "../styles/Index.module.css";
import MarkdownWithHighlight from "../components/MarkdownWithHighlight";
import MarkdownEditor from "../components/MarkdownEditor";
import { useUser } from "../contexts/user";
import ServiceOverview from "../components/ServiceOverview";
import SourcesList from "../components/SourceList";
import { supabaseClient } from "../utils/client";
import { predefinedQuestions } from "../utils/predefinedQuestions";
import * as mdUtils from "../utils/markdownEditorUtils";
import { BiSolidSend } from 'react-icons/bi';

export default function Index({ fetchMessages, setMessages, setChatId, currentChatId, setFadeIn, messages, resetSelectedChatId }) {
  const mainContainerRef = useRef(null);
  const editorRef = useRef(null);
  const { user, authInfo } = useUser();
  const router = useRouter();
  const [isLargerThan769] = useMediaQuery('(min-width: 769px)');
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showSkeletonSources, setShowSkeletonSources] = useState(false);
  const [fadeInState, setFadeInState] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  const scrollToBottom = () => {
    const container = mainContainerRef.current;
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    setIsVisible(false);
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, [router.pathname]);

  const addMessage = (message) => {
    message.role = message.role || "system";
    message.type = message.type || "text";
    setAutoScroll(true);
    setMessages((prevMessages) => {
      return [
        ...prevMessages,
        message
      ];
    });
  }

  const appendToLastMessage = (chatId, message, docs) => {
    setMessages((prevMessages) => {
      if (prevMessages.length === 0) {
        return prevMessages;
      }

      if (prevMessages[0].chat_id != chatId) { // If the chat id is different (due to page change), don't append
        return prevMessages;
      }

      const lastMsg = prevMessages[prevMessages.length - 1];
      if(message) {
        lastMsg['content'] += message;
      }
      if(docs) {
        lastMsg['retrieved_docs'] = docs;
      }
      return [
        ...prevMessages.slice(0, -1),
        lastMsg
      ];
    });
  }

  useEffect(() => {
    if (router.query.type === 'magiclink' && router.query.token) {
      try {
        supabaseClient
          .from('profiles')
          .upsert({
            id: user?.id,
            last_login: new Date().toISOString()
          })
          .then(({ data, error }) => {
            if (error) {
              console.error('Error updating login timestamp:', error.message);
            } else {
              console.log('Login timestamp updated:', data);
            }
          });
      } catch (error) {
        console.error('Error processing login:', error.message);
      }
    }
  }, [router.query, user?.id]);

  useEffect(() => {
    const loadMessagesForChat = async () => {
      if (currentChatId && !user.loading) {
        setLoading(true);
        setFadeIn(false);
        setMessages([]);
        setAutoScroll(true);
        const fetchedMessages = await fetchMessages(currentChatId, user.id);
        if (fetchedMessages) {
          setMessages(fetchedMessages);
        }
        setFadeIn(true);
        setLoading(false);
        scrollToBottom();
      }
    };

    if (initialLoad) {
      setInitialLoad(false);
    } else {
      loadMessagesForChat();
    }
  }, [currentChatId, user.id, user.loading]);

  const [userData, setUserData] = useState({ username: '', email: '' });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user?.id) {
          const { data: profileData, error } = await supabaseClient
            .from('profiles')
            .select('username')
            .eq('id', user.id)
            .single();

          if (error) {
            throw error;
          }

          const username = profileData.username ? profileData.username : user.email;
          setUserData({ username: username, email: user.email });
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUserData();
  }, [user?.id, user?.email]);

  useEffect(() => {
    const container = mainContainerRef.current;
    if (!container) return;

    const onScroll = () => {
      const isAtBottom = Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 10;
      setAutoScroll(isAtBottom);
    };

    container.addEventListener('scroll', onScroll);

    return () => {
      container.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (!autoScroll) return;

    const timer = setTimeout(() => {
      scrollToBottom();
    });

    return () => clearTimeout(timer);
  }, [messages, autoScroll]);

  const transitionStyles = {
    opacity: fadeInState ? 1 : 0,
    transition: 'opacity 0.4s cubic-bezier(0.7, 0, 0.84, 0)'
  };

  useEffect(() => {
    setFadeInState(true);
  }, [messages]);

  const isRootPath = router.pathname === "/";
  const hasChatQuery = !!router.query.chat;

  const editorVariants = {
    initial: {
      y: isRootPath ? "-45vh" : "-20px",
    },
    animate: {
      y: !initialLoad && router.query.chat ? "-20px" : isRootPath ? "-45vh" : "-20px",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
      },
    },
  };

  const editorVariantsMobile = {
    initial: {
      y: "30px",
      position: "fixed",
      bottom: isRootPath ? "calc(50vh + 30px)" : "-20px",
    },
    animate: {
      position: "fixed",
      left: "0",
      right: "0",
      bottom: isRootPath ? (hasChatQuery ? "calc(50vh)" : "calc(50vh - 150px)") : "calc(50vh)",
      y: hasChatQuery ? "calc(50vh - 25px)" : isRootPath ? "0px" : "calc(50vh - 25px)",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
      },
    },
  };

  const predefinedQuestionClicked = async (predefinedQuestion) => {
    let newChatId;

    if (user?.id) {
      newChatId = await mdUtils.createNewChat(user.id, predefinedQuestion.question);
    } else {
      newChatId = await mdUtils.createNewGuestChat(predefinedQuestion.question);
    }

    
    if (user?.id) {
      await mdUtils.saveMessageToSupabase(user.id, predefinedQuestion.question, newChatId, 'user');
      await mdUtils.saveMessageToSupabase(user.id, predefinedQuestion.answer, newChatId, 'ai-assistant');
    } else {
      await mdUtils.saveGuestMessageToSupabase(predefinedQuestion.question, newChatId, 'user');
      await mdUtils.saveGuestMessageToSupabase(predefinedQuestion.answer, newChatId, 'ai-assistant');
    }
    router.push(`/?chat=${newChatId}`, undefined, { shallow: true });
  };

  const handleSubmitFromButton = (content) => {
    if (editorRef.current) {
      editorRef.current.submitContent(content);
    }
  };

  const handleThumbsDownClick = async (messageId, currentThumbsDown) => {
    try {
      const newThumbsDown = !currentThumbsDown;
  
      if (user?.id) {
        await supabaseClient
          .from('chat_messages')
          .update({ thumbs_down: newThumbsDown })
          .eq('id', messageId);
      } else {
        await supabaseClient
          .from('chat_messages_guests')
          .update({ thumbs_down: newThumbsDown })
          .eq('id', messageId);
      }
  
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === messageId ? { ...message, thumbs_down: newThumbsDown } : message
        )
      );
    } catch (error) {
      console.error('Error updating thumbs down:', error.message);
    }
  };

  return (
    <>
      <Head>
        <title>Uprelic</title>
        <meta name="description" content="Uprelic" />
        <meta property="og:title" content={messages.find(msg => msg.role === 'user')?.content || 'Unleash Your Potential with Data and AI on Uprelic'} />
        <meta property="og:description" content={messages.find(msg => msg.role === 'ai-assistant')?.content || 'Unlock a new level of productivity by combining the power of your data, internet insights, and a cutting-edge AI assistant'} />
        <meta property="og:image" content="/path/to/og-image.jpg" />
        <meta property="og:url" content={`https://uprelic.com${router.asPath}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico?v=2" />
      </Head>
      <Box className={`main-container ${isVisible ? 'visible' : ''}`} overflowY="auto" h={isLargerThan769 ? "100vh" : "calc(100vh - 80px)"} ref={mainContainerRef}>
        <main className={styles.main}>
          {isLargerThan769 ? (
            <Grid
              h="100vh"
              templateColumns="repeat(6, 1fr)"
              templateRows="repeat(2, 1fr)"
              gap={4}
            >
              <GridItem colSpan={4} sx={transitionStyles}>
                <ChatContent
                  loading={loading}
                  currentChatId={currentChatId}
                  messages={messages}
                  userData={userData}
                  showSkeletonSources={showSkeletonSources}
                  predefinedQuestionClicked={predefinedQuestionClicked}
                  handleSubmitFromButton={handleSubmitFromButton}
                  handleThumbsDownClick={handleThumbsDownClick}
                />
              </GridItem>
              <GridItem colSpan={2} position="relative">
                <div className={styles.serviceOverviewContainer} style={{ position: "fixed", top: "0" }}>
                  <ServiceOverview />
                </div>
              </GridItem>
              <GridItem colSpan={4} bottom="0" position="fixed" style={{ width: 'calc(100% * 4 / 6 - 215px)' }} left='18.2em'>
                <motion.div initial="initial" animate="animate" variants={editorVariants}>
                  <Box className={styles.cloudform}>
                    <form>
                      <MarkdownEditor
                        loading={loading}
                        currentChatId={currentChatId}
                        add_message={addMessage}
                        appendToLastMessage={appendToLastMessage}
                        set_show_skeleton_sources={setShowSkeletonSources}
                        ref={editorRef}
                        resetSelectedChatId={resetSelectedChatId}
                      />
                    </form>
                  </Box>
                </motion.div>
              </GridItem>
            </Grid>
          ) : (
            <Flex flexDirection="column" sx={transitionStyles}>
              <ChatContent
                loading={loading}
                currentChatId={currentChatId}
                messages={messages}
                userData={userData}
                showSkeletonSources={showSkeletonSources}
                predefinedQuestionClicked={predefinedQuestionClicked}
                handleSubmitFromButton={handleSubmitFromButton}
                handleThumbsDownClick={handleThumbsDownClick}
              />
              <motion.div initial="initial" animate="animate" position="fixed" l="0" r="0" variants={editorVariantsMobile} style={{ width: '100%', padding: '0 1em' }}>
                <Box className={styles.cloudform}>
                  <form>
                    <MarkdownEditor
                      loading={loading}
                      currentChatId={currentChatId}
                      add_message={addMessage}
                      appendToLastMessage={appendToLastMessage}
                      set_show_skeleton_sources={setShowSkeletonSources}
                      ref={editorRef}
                      resetSelectedChatId={resetSelectedChatId}
                    />
                  </form>
                </Box>
              </motion.div>
            </Flex>
          )}
        </main>
      </Box>
    </>
  );
}

function ChatContent({ loading, messages, userData, showSkeletonSources, predefinedQuestionClicked, handleSubmitFromButton, handleThumbsDownClick }) {
  const AssistantIcon = () => (
    <Box borderRadius="full" bg="blue.500" w={8} h={8} display="flex" alignItems="center" justifyContent="center">
      <Image
        w="100%"
        h="auto"
        padding="5px"
        objectFit='cover'
        src='uprelic-icon-white-outline.svg'
        alt='Uprelic chat icon' />
    </Box>
  );

  const UserIcon = ({ userData }) => {
    const displayText = userData.username ? userData.username.charAt(0) : userData.email ? userData.email.charAt(0) : 'Y';
    return (
      <Box borderRadius="full" bg="cyan.600" w={8} h={8} display="flex" alignItems="center" justifyContent="center">
        <Text fontSize="sm" color="white" textTransform={'uppercase'}>
          {displayText}
        </Text>
      </Box>
    );
  };

  const HoverButton = ({ text, onClick }) => {
    return (
      <Button
        onClick={onClick}
        py="10px"
        pr="60px"
        colorScheme='blue'
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        _hover={{ '.hover-icon': { display: 'block' } }}
        whiteSpace="normal"
        textAlign="center"
      >
        <Box as="span" dangerouslySetInnerHTML={{ __html: text }} />
        <Button
          as={BiSolidSend}
          position="absolute"
          p="12px"
          right="10px"
          cursor="pointer"
          className="hover-icon"
          display="none"
          variant="solid"
          colorScheme="blue"
          opacity="0.5"
        />
      </Button>
    );
  };

  const ButtonGrid = () => {
    return (
      <SimpleGrid spacing="20px" columns={[1, null, 2]} mt="200px" opacity="0.7">
        <HoverButton
          text={predefinedQuestions[0].question}
          onClick={() => predefinedQuestionClicked(predefinedQuestions[0])}
        />
        <HoverButton
          text="Give me a gist of the last 24h of Slack messages"
          onClick={() => handleSubmitFromButton("Give me a gist of the last 24h of Slack messages")}
        />
        <HoverButton
          text="How to create a design system in Figma?"
          onClick={() => handleSubmitFromButton("How to create a designs systems in Figma?")}
        />
        <HoverButton
          text="Get all invoices from Gmail this month"
          onClick={() => handleSubmitFromButton("Get all invoices from Gmail this month")}
        />
      </SimpleGrid>
    );
  };

  return (
    <Box className={styles.chat}>
      {loading ? (
        <Center h="100vh"></Center>
      ) : (
        <>
          {messages.length === 0 && (
            <Box h="100vh" w="100%">
              <Heading as="h1" size="xl" textAlign="center" mt="calc(50vh - 260px)" >
                <Center>
                  <Image
                    w='100px'
                    h='auto'
                    padding='5px'
                    objectFit='cover'
                    src='uprelic-icon-gray.300-outline.svg'
                    alt='Uprelic chat icon'
                  />
                </Center>
                What can I do for you?
              </Heading>
              <ButtonGrid />
            </Box>
          )}
          <Box className={styles.messagelist}>
            {messages.map((message, index) => (
              <Box
                key={index}
                className={
                  message.role === "user" && messages.length - 1
                    ? styles.usermessage
                    : styles.apimessage
                }
              >
                <Flex flexDirection='column' w="100%">
                  <Box>
                    {message.role === "ai-assistant" ? (
                      <SourcesList sources={message.retrieved_docs} />
                    ) : (null)}
                  </Box>
                  {message.content ? (
                    <Flex flexDirection='column'>
                      <Flex flexDirection='row' alignItems='center'>
                        <Box>
                          {message.role === "ai-assistant" || message.role === "system" ? (
                            <AssistantIcon />
                          ) : (
                            <UserIcon userData={userData} />
                          )}
                        </Box>
                        <Box ml="10px">
                          {message.role === "user" ? (
                            <Text fontWeight="bold">You</Text>
                          ) : (
                            <Text fontWeight="bold">Uprelic</Text>
                          )}
                        </Box>
                      </Flex>
                      <Box mt="10px" className={message.type === "component" ? styles.componentMessage : styles.markdown}>
                        <MarkdownWithHighlight
                          text={message.content}
                          isAssistantAnswer={message.role === "ai-assistant"}
                          onThumbsDownClick={() => handleThumbsDownClick(message.id, message.thumbs_down)}
                          thumbsDown={message.thumbs_down}
                        />
                        {message.type === "button" && (
                          <button onClick={message.onClick} className={styles.messagebutton}>
                            {message.content}
                          </button>
                        )}
                        {message.type === "input" && (
                          <Input
                            onChange={message.onChange}
                            placeholder={message.placeholder}
                            className={styles.messageinput}
                          />
                        )}
                        {message.type === "component" && (
                          <message.content {...message.props} />
                        )}
                      </Box>
                    </Flex>
                  ) : (null)}
                </Flex>
              </Box>
            ))}
            {
              showSkeletonSources && <SourcesList showSkeleton={true} />
            }
          </Box>
        </>
      )}
    </Box>
  );
}
