import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    Editor,
    EditorState,
    RichUtils,
    Modifier,
    genKey,
    ContentBlock,
    BlockMapBuilder,
    CharacterMetadata,
    getDefaultKeyBinding,
    SelectionState
} from 'draft-js';

import styles from '../styles/MarkdownEditor.module.css';
import { Flex, Box, Collapse, useBreakpointValue, IconButton } from "@chakra-ui/react";

import { BiSolidSend } from "react-icons/bi";

import hljs from 'highlight.js/lib/core';
import { registerLanguages } from '../utils/highlightHelpers';

import { useUser } from "../contexts/user";
import { supabaseClient } from "../utils/client";
import * as mdUtils from "../utils/markdownEditorUtils";

import { useRouter } from 'next/router';

const MarkdownEditor = forwardRef((props, ref) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [placeholder, setPlaceholder] = useState("Ask me or give me instructions...");
    const [processedInput, setProcessedInput] = useState("");
    const [loading, setLoading] = useState(false);

    const { user } = useUser();
    const router = useRouter();
    const [isFocused, setIsFocused] = useState(false);
    const editorRef = useRef(null);

    const isMobile = useBreakpointValue({ base: true, md: false });
    const isGuestUser = !user.id;

    useEffect(() => {
        registerLanguages();
    }, []);

    useImperativeHandle(ref, () => ({
        submitContent: (content) => handleSubmit(content),
        focusEditor: () => {
            if (editorRef.current) {
                editorRef.current.focus();
            }
        }
    }));

    const focus = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (loading && editorRef.current) {
            editorRef.current.blur();
        }
    }, [loading, editorRef]);

    const myKeyBindingFn = (e) => {
        if (e.keyCode === 13 && !e.shiftKey && !loading) {
            return 'submit-on-enter';
        }
        return getDefaultKeyBinding(e);
    };

    const handleKeyCommand = (command, editorState) => {
        if (!editorState) {
            return 'not-handled';
        }

        if (command === 'submit-on-enter') {
            handleSubmit();
            return 'handled';
        }

        if (command === 'submit') {
            props.onSubmit();
            return 'handled';
        }

        if (command === 'backspace') {
            const selection = editorState.getSelection();
            if (selection.isCollapsed() && selection.getAnchorOffset() === 0) {
                return 'not-handled';
            }
        }

        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    const toggleBlockType = (blockType) => {
        let newEditorState = editorState;

        if (blockType === 'code-block') {
            const currentContent = newEditorState.getCurrentContent();
            const selection = newEditorState.getSelection();

            if (currentContent.getBlockForKey(selection.getStartKey()).getType() !== 'code-block') {
                const blockMap = currentContent.getBlockMap();
                const key = selection.getStartKey();
                const block = blockMap.get(key);

                const newCharacterList = block.getCharacterList().map(char => {
                    return CharacterMetadata.removeStyle(char, 'CODE');
                });

                const newBlock = block.set('characterList', newCharacterList);
                const newContentState = currentContent.set('blockMap', blockMap.set(key, newBlock));
                newEditorState = EditorState.push(newEditorState, newContentState, 'change-block-data');
            }
        }

        newEditorState = RichUtils.toggleBlockType(newEditorState, blockType);
        setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));
        setPlaceholder("");
    };

    const toggleInlineStyle = (inlineStyle) => {
        const selection = editorState.getSelection();
        const currentContent = editorState.getCurrentContent();
        const currentStyle = editorState.getCurrentInlineStyle();
        const blockType = currentContent.getBlockForKey(selection.getStartKey()).getType();

        if (blockType === 'code-block' && inlineStyle === 'CODE') {
            return;
        }

        let newEditorState = editorState;

        if (selection.isCollapsed() && !currentStyle.has(inlineStyle)) {
            const newContentState = Modifier.insertText(currentContent, selection, '\u200B', currentStyle.add(inlineStyle));
            newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
            newEditorState = EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
        } else {
            newEditorState = RichUtils.toggleInlineStyle(newEditorState, inlineStyle);
        }
        setPlaceholder("");
        setEditorState(newEditorState);
    };

    const onChange = (newEditorState) => {
        setEditorState(newEditorState);

        const contentState = newEditorState.getCurrentContent();
        const textIsEmpty = !contentState.hasText();
        const currentStyle = newEditorState.getCurrentInlineStyle();
        const selection = newEditorState.getSelection();
        const blockType = contentState.getBlockForKey(selection.getStartKey()).getType();
        const key = selection.getStartKey();
        const text = contentState.getBlockForKey(key).getText();

        if (selection.isCollapsed() && selection.getAnchorOffset() === text.length) {
            if (text.match(/^1\.\s$/)) {
                return setEditorState(createNewEditorState(newEditorState, 'ordered-list-item', 3));
            }
            if (text.match(/^\-\s$/)) {
                return setEditorState(createNewEditorState(newEditorState, 'unordered-list-item', 2));
            }
            if (text.match(/^```$/)) {
                setPlaceholder("")
                return setEditorState(createNewEditorState(newEditorState, 'code-block', 3));
            }
        }

        const inlineCodeMatch = text.match(/`([^`]+)`/);
        if (inlineCodeMatch) {
            const start = inlineCodeMatch.index;
            const end = start + inlineCodeMatch[0].length;
            const contentSelection = SelectionState.createEmpty(key).merge({
                anchorOffset: start,
                focusOffset: end,
            });

            let newContentState = Modifier.replaceText(
                contentState,
                contentSelection,
                inlineCodeMatch[1],
            );

            const styledSelection = contentSelection.merge({
                anchorOffset: start,
                focusOffset: start + inlineCodeMatch[1].length,
            });
            newContentState = Modifier.applyInlineStyle(
                newContentState,
                styledSelection,
                'CODE'
            );

            const spaceSelection = styledSelection.merge({
                anchorOffset: styledSelection.getFocusOffset(),
                focusOffset: styledSelection.getFocusOffset(),
            });
            newContentState = Modifier.insertText(
                newContentState,
                spaceSelection,
                ' '
            );

            const updatedSelection = spaceSelection.merge({
                anchorOffset: spaceSelection.getFocusOffset() + 1,
                focusOffset: spaceSelection.getFocusOffset() + 1,
                hasFocus: true,
            });

            const updatedEditorState = EditorState.forceSelection(
                EditorState.push(newEditorState, newContentState, 'change-inline-style'),
                updatedSelection
            );

            return setEditorState(updatedEditorState);
        }

        setEditorState(newEditorState);
        const isStyleApplied = blockType !== 'unstyled' || currentStyle.size > 0;
        setPlaceholder(textIsEmpty && !isStyleApplied ? "Ask me or give me instructions..." : "");
    };

    function createNewEditorState(editorState, blockType, textLength) {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const newContentState = Modifier.removeRange(contentState, selection.merge({
            anchorOffset: 0,
            focusOffset: textLength,
        }), 'backward');

        const newState = EditorState.push(editorState, newContentState, 'remove-range');
        return RichUtils.toggleBlockType(newState, blockType);
    }

    const isEditorEmpty = () => {
        const contentState = editorState.getCurrentContent();
        return !contentState.hasText();
    };

    const handleError = () => {
        props.add_message({ content: "Oops! There seems to be an error. Please try again." });
        setLoading(false);
    };

    const handleSubmit = async (content) => {
        let newProcessedInput = content;

        if (typeof newProcessedInput !== 'string') {
            const contentStateAsMarkdown = mdUtils.customStateToMarkdown(editorState.getCurrentContent());
            const contentState = editorState.getCurrentContent();
            const plainUserInput = contentState.getPlainText();

            const detectLanguage = (code, languages) => {
                const results = languages.map(language => ({
                    language,
                    ...hljs.highlight(code, { language }).relevance
                }));

                results.sort((a, b) => b.relevance - a.relevance);
                return results[0]?.language;
            };

            const languages = ['javascript', 'xml', 'css', 'python', 'sql', 'typescript', 'shell', 'java', 'csp', 'cpp', 'c', 'php', 'powershell', 'go', 'rust', 'kotlin', 'ruby', 'lua', 'dart', 'armasm', 'swift', 'r'];
            newProcessedInput = contentStateAsMarkdown.replace(/```([^`]+)```/g, (match, code) => {
                const detectedLang = detectLanguage(code, languages);
                return `\`\`\`${detectedLang}\n${code}\n\`\`\``;
            });
            newProcessedInput = newProcessedInput.trim();

            if (contentStateAsMarkdown.trim() === "") {
                return;
            }
        }

        let chatId = props.currentChatId;
        let newChatCreated = false;
        if (!chatId) {
            chatId = isGuestUser
                ? await mdUtils.createNewGuestChat(newProcessedInput)
                : await mdUtils.createNewChat(user.id, newProcessedInput);
            newChatCreated = true;

            if (!chatId) { handleError(); return; }
        }

        if (newChatCreated) {
            router.push(`/?chat=${chatId}`, undefined, { shallow: true });
            console.log("pushed to ", `/?chat=${chatId}`);
        }

        setProcessedInput(newProcessedInput);
        setLoading(true);
        setEditorState(EditorState.createEmpty());
        setPlaceholder("Ask me or give me instructions...");

        props.add_message({ content: newProcessedInput, role: 'user' });
        props.set_show_skeleton_sources(true);

        let aiMessageId = null;
        let TABLE_FOR_SUBSCRIPTION = null;
        let STREAM_ENDPOINT = null;
        if (isGuestUser) {
            await mdUtils.saveGuestMessageToSupabase(newProcessedInput, chatId, 'user');
            aiMessageId = await mdUtils.saveGuestMessageToSupabase("", chatId, 'ai-assistant');
            TABLE_FOR_SUBSCRIPTION = 'chat_messages_guests';
            STREAM_ENDPOINT = "/api/stream-guest";
        } else {
            await mdUtils.saveMessageToSupabase(user.id, newProcessedInput, chatId, 'user');
            aiMessageId = await mdUtils.saveMessageToSupabase(user.id, "", chatId, 'ai-assistant');
            TABLE_FOR_SUBSCRIPTION = 'chat_messages';
            STREAM_ENDPOINT = "/api/stream";
        }
        props.add_message({ content: '', role: 'ai-assistant', id: aiMessageId });

        const subscription = supabaseClient
            .channel('retrieveDocsUpdate')
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: TABLE_FOR_SUBSCRIPTION, filter: `chat_id=eq.${chatId}` }, payload => {
                if (payload.new.retrieved_docs) {
                    props.set_show_skeleton_sources(false);
                    props.appendToLastMessage(chatId, null, payload.new.retrieved_docs);
                }
            })
            .subscribe();

        try {
            const response = await fetch(STREAM_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    q: newProcessedInput,
                    chatId: chatId,
                    userId: user.id,
                    messageId: aiMessageId,
                })
            });

            setProcessedInput(false);

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let result = await reader.read();
            let answer = "";

            while (!result.done) {
                const text = decoder.decode(result.value);
                answer += text;

                props.appendToLastMessage(chatId, text, null);
                result = await reader.read();
            }

            if (isGuestUser) {
                await mdUtils.updateGuestMessageToSupabase(aiMessageId, answer);
            } else {
                await mdUtils.updateMessageToSupabase(aiMessageId, answer);
            }

            if (response && response.ok) {
            } else {
                handleError();
            }
        } catch (error) {
            console.error(error);
            handleError();
        } finally {
            if (subscription) {
                subscription.unsubscribe();
            }
        }

        setLoading(false);
    };

    const isContentEmpty = isEditorEmpty();

    const editorClasses = [styles.RichEditorEditor];
    if (loading) {
        editorClasses.push(styles.EditorReadOnly);
    }

    const handlePastedText = (text, _, editorState) => {
        const shouldBeCodeBlock = text.includes('\n');

        if (shouldBeCodeBlock) {
            const newBlock = new ContentBlock({
                key: genKey(),
                type: 'code-block',
                text: text
            });

            const contentState = editorState.getCurrentContent();
            const newContentState = Modifier.replaceWithFragment(
                contentState,
                editorState.getSelection(),
                BlockMapBuilder.createFromArray([newBlock])
            );

            const newState = EditorState.push(editorState, newContentState, 'insert-fragment');
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    return (
        <Box className={styles.RichEditorRoot}>
            {isMobile ? (
                <Collapse in={isFocused} animateOpacity unmountOnExit transition={{ enter: { duration: 0.2 }, exit: { duration: 0.1 } }}>
                    <Flex
                        direction="row"
                        justify="start"
                        align="center"
                        p="10px 10px 0 10px"
                        borderRadius="1rem 1rem 0 0"
                        border="1px solid #30373d"
                        bg="#1A202C"
                        borderBottom="none"
                        mb="-1px"
                    >
                        <mdUtils.BlockStyleControls
                            editorState={editorState}
                            onToggle={toggleBlockType}
                            editorRef={editorRef}
                        />
                        <mdUtils.InlineStyleControls
                            editorState={editorState}
                            onToggle={toggleInlineStyle}
                            editorRef={editorRef}
                        />
                    </Flex>
                </Collapse>
            ) : (
                <Flex
                    direction="row"
                    justify="start"
                    align="center"
                    p="10px 10px 0 10px"
                    borderRadius="1rem 1rem 0 0"
                    border="1px solid #30373d"
                    bg="#1A202C"
                    borderBottom="none"
                    mb="-1px"
                >
                    <mdUtils.BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                        editorRef={editorRef}
                    />
                    <mdUtils.InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                        editorRef={editorRef}
                    />
                </Flex>
            )}
            <Box
                className={`${styles.RichEditorEditor} ${isFocused ? styles.RichEditorEditorFocused : ''}`}
                onClick={focus}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
            >
                <Editor
                    blockStyleFn={mdUtils.getBlockStyle}
                    customStyleMap={mdUtils.styleMap}
                    editorState={editorState}
                    keyBindingFn={myKeyBindingFn}
                    handleKeyCommand={handleKeyCommand}
                    onChange={onChange}
                    placeholder={placeholder}
                    ref={editorRef}
                    spellCheck="true"
                    handlePastedText={handlePastedText}
                    handleBeforeInput={(char) => {
                        if (char === '\b') {
                            return 'handled';
                        }
                        return 'not-handled';
                    }}
                />
            </Box>
            <mdUtils.CustomIconButton
                icon={<BiSolidSend />}
                position="absolute"
                bottom="12px"
                right="12px"
                aria-label="Submit"
                isLoading={loading}
                isActive={!isContentEmpty && !loading}
                onClick={() => handleSubmit()}
            />
        </Box>
    );
});

export default MarkdownEditor;
